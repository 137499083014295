import React, { useEffect, useState } from "react";
// import { Buttons, Cards, Navbar, NeftForm, StudentIDCard } from "./Navbar";
// import './Dash.css'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LcForm from "../pages/lc_form/LCForm";
// import LcForm from "./lc_form/LCForm";

const StatusLc = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([])
  const [SingleFee, setSingleFee] = useState([])
  const [message, setMessage] = useState(false);
  const [lcStatus, setLcStatus] = useState([]);
  const [isCompleted, setIsCompleted] = useState([]);

  useEffect(() => {
    fetchInfo();
    fetchAmount();
    fetchLCStatus();
  }, []);

  const fetchInfo = () => {
      axios.get(`${process.env.REACT_APP_BASE_URL}/profile`,{ headers: { Authorization: localStorage.getItem('token')}})
      . then((response)=>
        {
          setData(response.data);
        })
        .catch(err=>
          {
          console.log(err.response.data);
          }
      );
  }

  const fetchAmount = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/single_fee_amounts`,{ headers: { Authorization: localStorage.getItem('token')}})
      . then((response)=>
        {
          setSingleFee(response.data);
        })
        .catch(err=>
          {
          if(err.response.data.message=='Forbidden: Invalid token')
          {
            alert("Session Has been Expired!.");
            localStorage.clear()
            window.location.reload();
          }
          }
      );
  }

  const fetchLCStatus = async() =>{
    axios.get(`${process.env.REACT_APP_BASE_URL}/getLCStatus`,{ headers: { Authorization: localStorage.getItem('token')}})
      . then((response)=>
        {
          if(Number(response.data.lcFlag)!=0){
            setLcStatus(response.data.data);
            setIsCompleted(response.data.complete)
          }
          else{
            setMessage('You did not applied for LC yet');
          }
        })
        .catch(err=>
          {
          if(err.response.data.message=='Forbidden: Invalid token')
          {
            alert("Session Has been Expired!.");
            localStorage.clear()
            window.location.reload();
          }
          }
      );
  }

  const handleRemarkUpdate = async(hod_id)=>{
    axios.post(`${process.env.REACT_APP_BASE_URL}/updateRemark`,{hod_id:hod_id},{ headers: { Authorization: localStorage.getItem('token')}})
      . then((response)=>
        {
          if(Number(response.data.success)!=0){
            alert('Updated Application')
            window.location.reload()
          }
        })
        .catch(err=>
          {
          if(err.response.data.message=='Forbidden: Invalid token')
          {
            alert("Session Has been Expired!.");
            localStorage.clear()
            window.location.reload();
          }
          }
      );
  }

  var FeeItems = []
  if(SingleFee.length>0){
    for(var i=0;i<SingleFee.length;i++){
      FeeItems.push(
        <div class="card col-lg-3" style={{minWidth: "18rem",width:"fit-content"}}>
        <div class="card-header">
          {SingleFee[i].head_name}
        </div>
        <div class="card-body">
          <h5 class="card-title m-3">{SingleFee[i].amount} Rs.</h5>
          <form action={`https://vppcoe-va.getflytechnologies.com/payment/alumni_initiate_payment?id=${SingleFee[i].sfh_id}`} method='POST' id='integration' >
                <button className='btn btn-primary' name='collegeId' value={data.collegeId}>
                  Pay Fees
                </button>
              </form>
        </div>
      </div>
      )
      
    }
  }


  const lcItems = [];

  if(lcStatus.length>0){
    lcStatus.forEach(e=>{
      let remarkItems = []

          {
            (e.remarks!=null  || e.remarks!=undefined) ?
            <>
            {e.remarks.length>0 ? 
              JSON.parse(e.remarks).forEach(s=>{
                remarkItems.push(
                  <option>
                    {console.log(s.remark)}
                    {s.remark}
                  </option>
                )
              })
            :
            <></>}</> : <></>
          }

      lcItems.push(
        <tr>
          <td>{e.sr_no}</td>
          <td>{e.name}</td>
          <td>{e.department}</td>
          <td>
            <select style={{width:'150px'}}>
            {remarkItems}
             </select>
             </td>

             <td>
              {
                Number(e.status)==0 ? "Pending" : "Approved"
              }
             </td>

             <td>
              <div>
              <button className="btn btn-primary" onClick={()=>{handleRemarkUpdate(e.hod_id)}}>
                Update
              </button>
              </div>
              
             </td>
        </tr>
      )
    })
  }
  
  return (
    <>
      <div className="mycontainer_withH">
      <div className="container dashboard d-flex justify-content-between row g-3" >

        <div class="card col-lg-12 d-flex " style={{width: "100%",flexDirection:"row",flexWrap:"wrap",padding:'20px',gap:'10px'}}>
          
          <ul class="list-group list-group-flush w-100">
            <li class="list-group-item"><h4>Leaving Certificate Status</h4></li>
          </ul>

        <div className="table-responsive" style={{width: "100%",flexDirection:"row",flexWrap:"wrap",padding:'20px',gap:'10px'}}>
        
          {lcItems.length!=0 ?
          <table class="table table-hover m-3" >
            <tr>
                <th>Sr Number</th>
                <th>Name</th>
                <th>Department</th>
                <th>Remark</th>
                <th>Status</th>
                <th>Update</th>
            </tr>
            {lcItems}
          </table>:<><h5>Not Applied For LC</h5></>
          }
        {
            Number(isCompleted)==1 ?
            <><div className="w-100 text-center"><button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#fillform">Fill Leaving Certificate Form</button></div></>
            :
            <></>
          }

            <div class="modal fade" id="fillform" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Fill All Details Carefully!</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                   <LcForm/>
                  </div>
                  
                </div>
              </div>
            </div>
        
        </div>
       
        </div>



            
   </div>
   </div>

    </>
 
  );
};

export default StatusLc;