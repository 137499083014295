import axios from "axios";
import { Component } from "react";

class LcForm extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    handleSubmitButton = async (e) => {
        e.preventDefault();

        const formData = Object.fromEntries(new FormData(e.target));

        console.log(formData)

        const isValid = Object.values(formData).every((value) => value.trim() !== "");

        if (!isValid) {
            alert("Please fill in all fields.");
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/ApplyLeavingCertificate`, formData,{ headers: { Authorization: localStorage.getItem('token')}});
            alert(response.data.message);
        } catch (error) {
            console.error("Error making Axios request:", error);
        }
    };
    

    render() {
        return (
            <>
                <form
                    class="forms"
                    onSubmit={(e)=>this.handleSubmitButton(e)}
                >

                   
                    <div class="mb-3">
                        <label>Seat Number (Refer this from old Marksheet)</label>
                        <input
                            class="form-control"
                            type="text"
                            name="seat_number"
                            id="setn"
                        />
                    </div>
                    <div class="mb-3">
                        <label>Academic Year</label>
                        <input
                            class="form-control"
                            type="text"
                            name="ay"
                            id="ay"
                            required
                        />
                    </div>
                    <div class="mb-3">
                        <label>Religion</label>
                        <input
                            class="form-control"
                            type="text"
                            name="religion"
                            id="religion"
                        />
                    </div>
                    <div class="mb-3">
                        <label>Cast</label>
                        <input
                            class="form-control"
                            type="text"
                            name="cast"
                            id="cast"
                        />
                    </div>

                    <div class="mb-3">
                        <label>Nationality</label>
                        <input
                            class="form-control"
                            type="text"
                            name="Nationality"
                            id="Nationality"
                        />
                    </div>
                    <div class="mb-3">
                        <label>Place of Birth</label>
                        <input
                            class="form-control"
                            type="text"
                            name="pob"
                            id="pob"
                        />
                    </div>
                    <div class="mb-3">
                        <label>Date of Birth</label>
                        <input
                            class="form-control"
                            type="date"
                            name="dob"
                            id="dob"
                        />
                    </div>
                    <div class="mb-3">
                        <label>Date of Birth(In words)</label>
                        <input
                            class="form-control"
                            type="text"
                            name="dobw"
                            id="dobw"
                        />
                    </div>
                    <div class="mb-3">
                        <label>Last School/College Attended</label>
                        <input
                            class="form-control"
                            type="text"
                            name="cattended"
                            id="cattended"
                        />
                    </div>
                    <div class="mb-3">
                        <label>Date of First Admission in College</label>
                        <input
                            class="form-control"
                            type="date"
                            name="dof"
                            id="dof"
                        />
                    </div>
                    <div class="mb-3">
                        <label>Last Class Attended (eg: B.E. Information Technology)</label>
                        <input
                            class="form-control"
                            type="text"
                            name="lcattended"
                            id="lcattended"
                        />
                    </div>
                    <div class="mb-3">
                        <label>Date of Leaving the College</label>
                        <input
                            class="form-control"
                            type="date"
                            name="dol"
                            id="dol"
                        />
                    </div>
                    <div class="mb-3">
                        <label>Last University Exam Attended (Month, Year)</label>
                        <input
                            class="form-control"
                            type="text"
                            name="exam"
                            id="exam"
                        />
                    </div>
                    <div class="mb-3">
                        <label>Start Date of Last Year</label>
                        <input
                            class="form-control"
                            type="Date"
                            name="sw"
                            id="sw"
                        />
                    </div>
                    <div class="mb-3">
                        <label>Reason For Leaving (eg: Passed B.E. Information Technology)</label>
                        <input
                            class="form-control"
                            type="text"
                            name="rl"
                            id="rl"
                        />
                    </div>
                    <div class="mb-3">
                        <label>CGPA Scored</label>
                        <input
                            class="form-control"
                            type="text"
                            name="cgpa"
                            id="cgpa"
                        />
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Submit</button>
                  </div>
                </form>

                
            </>
        )
    }
}

export default LcForm;